import { Cloud$User } from '@otovo/shared/types/cloudApi';
import { setUser } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { validToken } from '../../../utils/auth/auth';
import { UnableToGetUserProfileError } from './errors/UnableToGetUserProfileError';
import fetchProfile from './fetchProfile';

const sendRequest = async () => {
  const user = await fetchProfile();
  setUser({
    email: user.email,
  });
  return user;
};

const ONE_MINUTE = 60000;

type Args = {
  redirectToLogin?: boolean;
};

export default function useProfile({ redirectToLogin = true }: Args = {}) {
  const router = useRouter();
  const { asPath } = router;
  // Fetch the user profile only if we have a token. Note that this hook won't
  // automatically run again when the token is set.
  const isReady = validToken();
  const {
    data: user,
    error,
    isLoading,
  } = useSWR<Cloud$User>(isReady ? 'useProfile' : null, sendRequest, {
    dedupingInterval: ONE_MINUTE,
    revalidateOnFocus: false,
  });

  if (__isBrowser__) {
    const requiresLogin = !isReady;
    if (
      error instanceof UnableToGetUserProfileError ||
      (redirectToLogin && requiresLogin)
    ) {
      if (!asPath.startsWith('/login')) {
        router.push(`/login?next=${encodeURIComponent(asPath)}`);
      }
      return {
        user: null,
        error,
        isLoading: false,
      };
    }
  }

  return {
    user,
    error,
    isLoading,
  };
}
