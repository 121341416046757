import Navbar from '@otovo/shared/components/Navigation/Navbar';
import NavSlideoverMenu from '@otovo/shared/components/Navigation/NavSlideoverMenu';
import {
  ProductPageMenuLinks,
  ProductPageNavLinks,
} from '@otovo/shared/components/Navigation/ProductPageLinks';
import { useNavbarLinks } from '../hooks/useNavbarLinks';

const MyPageNav = () => {
  return (
    <Navbar navItems={<ProductPageNavLinks includeDomainInUrls={true} />}>
      <NavSlideoverMenu useLinks={useNavbarLinks}>
        <ProductPageMenuLinks includeDomainInUrls={true} />
      </NavSlideoverMenu>
    </Navbar>
  );
};

export default MyPageNav;
