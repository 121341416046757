import { Cloud$User } from '@otovo/shared/types/cloudApi';
import { fetchWithToken } from '../../../utils/api';
import { UnableToGetUserProfileError } from './errors/UnableToGetUserProfileError';

export default async function fetchProfile() {
  const getUserProfileRequest = await fetchWithToken(
    `${process.env.NEXT_PUBLIC_OTOVOAPI_URL}/id/me`,
    {},
  );

  if (!getUserProfileRequest.ok) {
    throw new UnableToGetUserProfileError();
  }

  const user = (await getUserProfileRequest.json()) as Cloud$User;
  return user;
}
